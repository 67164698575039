@import url(https://use.typekit.net/gqo8sup.css);
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: masqualero, sans-serif;
  background-color: #000;
}

#container {
  height: 100vh;
  width: 100vw;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40vmin;
  height: 100vh;
  width: 100vw;
  transition: 0.3s opacity ease-in-out;
}

.loading.loaded {
  opacity: 0;
}

.loading .text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 10vmin;
}

.slides {
  height: 100vh;
  width: 100vw;
  position: relative;
  opacity: 0;
  transition: 0.3s opacity ease-in-out;
}

.slides.visible {
  opacity: 1;
}

.slide {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  opacity: 0;
  position: absolute;
  transition: 0.3s opacity ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  font-size: 10vmin;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 0 10vmin;
}

.slide.visible {
  opacity: 1;
}

